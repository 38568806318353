import { useSelector } from 'react-redux';
import Cover from '../../components/Cover/Cover'
import './ContactsPage.css'

const ContactsPage = () => {
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);

  return (
    <div className='contacts__page'>
        <Cover coverDescription={"Контакти"} />
        <div className="contacts__wrapper">
            <div className={`contacts__adress ${isDarkTheme ? "" : "light"}`}>
              <p className={`adress ${isDarkTheme ? "" : "light"} mont-m`}>м. Чернігів</p>
              <p className={`adress ${isDarkTheme ? "" : "light"} mont-m`}>вул. Святомиколаївська, 19</p>
            </div>
            <div className={`contacts__connect ${isDarkTheme ? "" : "light"}`}>
              <a className={`tel ${isDarkTheme ? "" : "light"} mont-m`} href="tel:+38(063)503-04-72">+38(063)503-04-72</a>
              <a className={`mail ${isDarkTheme ? "" : "light"} mont-m`} href="mailto:filada.clinic@gmail.com">filada.clinic@gmail.com</a>
            </div>
            <div className={`contacts__time ${isDarkTheme ? "" : "light"}`}>
              <p className={`time ${isDarkTheme ? "" : "light"} mont-m`}>8:00 - 20:00</p>
              <p className={`days ${isDarkTheme ? "" : "light"} mont-m`}>Без вихідних</p>
            </div>
        </div>
        <section className="map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9937.058092520356!2d31.3039185!3d51.4900131!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46d5492fe0c4212f%3A0x835a19a342e61be!2sMedychnyy%20Tsentr%20Filada!5e0!3m2!1sru!2sua!4v1726743869362!5m2!1sru!2sua"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title='карта як нас знайти'
      ></iframe>
    </section>
    </div>
  )
}

export default ContactsPage


