import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logo from "../../assets/icons/logo.png";
import { setBurgerIsActive, setBurgerIsClose } from "../../redux/BurgerSlice/BurgerSlice";
import { toggleTheme } from "../../redux/ToggleThemeSlice/ToggleThemeSlice";
import { setClearIsFormSended, setIsActive, setIsClose } from "../../redux/ModalSlice/ModalSlice";
import { ABOUTE_ROUTE, CONTACTS_ROUTE, PRICES_ROUTE, SERVICE_COSMETOLOGY_ROUTE, SERVICE_GINECOLOGY_ROUTE, SERVICE_PASTIC_ROUTE, SERVICE_PROCTOLOGY_ROUTE, SERVICE_VESSELS_ROUTE } from "../../utils/routes";
import ToggleThemeButton from "../ToggleThemeButton/ToggleThemeButton";
import "./Header.css";
import Triangle from "./Triangle/Triangle";
import Hamburger from "hamburger-react";

const Header = () => {
  const dispatch = useDispatch();
  const [isMenuActive, setIsMenuActive] = useState(false);
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const [isChecked, setIsChecked] = useState(isDarkTheme);
  const isActive = useSelector((state) => state.burger.isBurgerActive);
  const [isOpen, setOpen] = useState(isActive);

  const handleMenuOpen = () => {
    setIsMenuActive(true);
  };

  const handleMenuClose = () => {
    setIsMenuActive(false);
  };

  const handleModalOpen = () => {
    dispatch(setIsActive());
    dispatch(setBurgerIsClose());
    setOpen(false);
  };

  const handleToggleTheme = () => {
    dispatch(toggleTheme());
    setIsChecked((prev) => !prev);
  };

  useEffect(() => {
    setIsChecked(isDarkTheme);
    setOpen(isActive);
  }, [isDarkTheme, isActive]);

  return (
    <header className={`header ${isDarkTheme ? "" : "light"}`}>
      <div className="header__wrapper">
        <Link to={"/"}>
          <img
            src={logo}
            alt="логотип медичного центру Філада"
            className="header__logo"
          />
        </Link>
        <nav className="header__nav">
          <div className="header__link-first"
           onMouseOver={handleMenuOpen}>
            <div
              onMouseLeave={handleMenuClose}
              className={`header__menu ${isDarkTheme ? "" : "light"} ${
                isMenuActive ? "active" : ""
              }`}
            >
              <Link
                onClick={handleMenuClose}
                to={SERVICE_PASTIC_ROUTE}
                className={`header__menu-link ${
                  isDarkTheme ? "" : "light"
                } mont-r`}
              >
                Пластична хірургія
              </Link>
              <Link
                onClick={handleMenuClose}
                to={SERVICE_GINECOLOGY_ROUTE}
                className={`header__menu-link ${
                  isDarkTheme ? "" : "light"
                } mont-r`}
              >
                Гінекологія
              </Link>
              <Link
                onClick={handleMenuClose}
                to={SERVICE_COSMETOLOGY_ROUTE}
                className={`header__menu-link ${
                  isDarkTheme ? "" : "light"
                } mont-r`}
              >
                Косметологія та дерматологія
              </Link>
              <Link
                onClick={handleMenuClose}
                to={SERVICE_PROCTOLOGY_ROUTE}
                className={`header__menu-link ${
                  isDarkTheme ? "" : "light"
                } mont-r`}
              >
                Проктологія
              </Link>
              {/* <Link
                onClick={handleMenuClose}
                to={SERVICE_VESSELS_ROUTE}
                className={`header__menu-link ${
                  isDarkTheme ? "" : "light"
                } mont-r`}
              >
               УЗ діагностика
              </Link> */}
            </div>
              <p
                className={`header__link ${isDarkTheme ? "" : "light"}`}
              >
                Послуги
              </p>
              <div className={`triangle ${isMenuActive ? "active" : ""} `}>
                {" "}
                <Triangle isActive={isMenuActive} />
              </div>
          </div>
          <Link className={`header__link ${isDarkTheme ? "" : "light"}`} to={ABOUTE_ROUTE}>
            Про нас
          </Link>
          <Link className={`header__link ${isDarkTheme ? "" : "light"}`} to={PRICES_ROUTE}>
            Ціни
          </Link>
          <Link className={`header__link ${isDarkTheme ? "" : "light"}`} to={CONTACTS_ROUTE}>
            Контакти
          </Link>
        </nav>
        <div className="header__buttons">
        <div
            // onClick={handleToggleTheme}
            className="toggle__header-button-wrapper"
          >
            <ToggleThemeButton onChange={handleToggleTheme} isChecked={!isChecked} />
          </div>
          <a className={`header__call ${isDarkTheme ? "" : "light"}`} href="tel:+38(063)503-04-72">
            +38(063)503-04-72
          </a>
          <button onClick={handleModalOpen} className={`header__taget-button ${isDarkTheme ? "" : "light"}`}>Консультація</button>
          <div className="burger__button">
            <Hamburger
             size={28}
             toggled={isOpen}
              onToggle={(toggled) => {
                if (toggled) {
                  dispatch(setIsClose())
                  dispatch(setBurgerIsActive())
                  dispatch(setClearIsFormSended());
                  setOpen(true)
                } else {
                  dispatch(setBurgerIsClose())
                  setOpen(false)
                }
              }}
            />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
