import { useSelector } from "react-redux";
import "./ServiceAbout.css";

const ServiceAbout = ({ serviceTitle, serviceTexts, servicePhotos }) => {
  const [serviceTextOne, serviceTextTwo, serviceTextThree] = [...serviceTexts];
  const [servicePhotoOne, servicePhotoTwo, servicePhotoThree] = [
    ...servicePhotos,
  ];
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);

  return (
    <section className="service__aboute">
      <div className="service__aboute-texts">
        <h2 className={`service-about-title arial-r ${isDarkTheme ? "" : "light"}`}>{serviceTitle}</h2>
        <p className="service-about-description">{serviceTextOne}</p>
        <p className="service-about-description">{serviceTextTwo}</p>
        <p className="service-about-description">{serviceTextThree}</p>
      </div>
      <div className="service__about-photos">
        <div className="service__about-photos-small">
          <img src={servicePhotoOne} alt="" className="service__about-image" />
          <img src={servicePhotoTwo} alt="" className="service__about-image" />
        </div>
        <img src={servicePhotoThree} alt="" className="service__about-image-large" />
      </div>
    </section>
  );
};

export default ServiceAbout;
