import Cover from "../../components/Cover/Cover"
import { welcomeArr } from "../../utils/welcomeArr"
import MainAbout from "./MainAbout/MainAbout"
import MainFAQ from "./MainFAQ/MainFAQ"
import MainReasons from "./MainReasons/MainReasons"
import MainServices from "./MainServices/MainServices"
import MainWelcome from "./MainWelcome/MainWelcome"
import './MainPage.css'

const MainPage = () => {
  return (
    <div className="main__page">
      <Cover coverDescription={"Тут краса зустрічається зі здоров’ям"} />
      <MainAbout />
      <MainServices />
      <MainWelcome welcomeArr={welcomeArr} />
      <MainReasons />
      <MainFAQ />
    </div>
  )
}

export default MainPage