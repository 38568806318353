import Cover from '../../components/Cover/Cover'
import './AboutePage.css'
import AbouteTeam from './AbouteTeam/AbouteTeam'
import AbouteValues from './AbouteValues/AbouteValues'
const AboutePage = () => {
  return (
    <div className='aboute__page'>
      <Cover coverDescription={"Тут краса зустрічається зі здоров’ям"} />
      <AbouteValues />
      <AbouteTeam />
    </div>
  )
}

export default AboutePage