import Cover from '../../components/Cover/Cover'
import PricesAccordion from './PricesAccordion/PricesAccordion'
import './PricesPage.css'

const PricesPage = ({serviceArr}) => {
  return (
    <div className='prices__page'>
         <Cover coverDescription={"Ціни"} />
         <PricesAccordion serviceArr={serviceArr}/>
    </div>
  )
}

export default PricesPage