import './Cover.css'
import coverImage from '../../assets/images/CoverHome.webp'
import coverMobile from '../../assets/images/mobile-cover.webp'

const Cover = ({coverDescription}) => {
  const isMobile = window.innerWidth <= 1199;

  return (
    <div className='cover'>
        <img src={isMobile? coverMobile : coverImage} alt="обкладинка сторінки" className="cover__image" />
        <h2 className="cover__description mont-r">{coverDescription}</h2>
    </div>
  )
}

export default Cover