import './ServicePage.css'
import Cover from "../../components/Cover/Cover"
import ServiceAbout from './ServiceAbout/ServiceAbout'
import ServicePrices from './ServicePrices/ServicePrices'

const ServicePage = ({ coverDescription, servicePhotos, serviceTitle, serviceTexts, pricesObj }) => {
  return (
    <div className="service__page">
      <Cover coverDescription={coverDescription} />
      <ServiceAbout servicePhotos={servicePhotos} serviceTexts={serviceTexts} serviceTitle={serviceTitle}/>
      <ServicePrices services={pricesObj.services} isComplicated={pricesObj.isComplicated}/>
    </div>
  )
}

export default ServicePage